import "./Card.css";

export const Card = ({small, number, onClick}) => {
  const cssClass = small ? "small" : "large";
  
  const quotient = (number % 25) + 1;
  const imagePath =  `/images/${quotient}.jpg`;
  

  return (
    <div className={`cardContainer ` + cssClass}  onClick={() => onClick(number)}>
      <div className="card-sm-num" style={{ display: "flex", justifyContent: "start" }}>
        <img src={imagePath}/>
      </div>
      <div className="card-lg-num text" >{number}</div>
      <div className="card-sm-num"  style={{ display: "flex", justifyContent: "end" }}>
        <img src={imagePath}/>
      </div>
    </div>
  );
};


Card.defaultProps = {
  onClick: () => {}
};