// import './PlayersCards.css';
import "./PlayerCards.css";

export const PlayersCards = ({ cards }) => {
  const renderCards = () => {
    if (cards != undefined) {
      const components = [];
      for (var card in cards) {
        components.push(<OnePlayersCards numberOfCards={cards[card]} />);
      }
      return components;
    } else return <></>;
  };
  return <div className="player-cards-container">{renderCards()}</div>;
};

const OnePlayersCards = ({ numberOfCards }) => {
  const renderRow = (num, rowNumber) => {
    const cards = [];
    for (let i = 0; i < num; i++) {
      cards.push(
        <div
          className="playersCardItem"
          style={{
            zIndex: i + (1000 + rowNumber),
            left: i * 8,
            top: rowNumber * 15,
          }}
        ></div>
      );
    }
    return cards;
  };
  const renderRow1 = () => {
    if (numberOfCards > 4) return renderRow(4, 1);
    else return renderRow(numberOfCards, 1);
  };
  const renderRow2 = () => {
    if (numberOfCards > 8) return renderRow(4, 2);
    else if (numberOfCards > 4) return renderRow(numberOfCards - 4, 2);
  };
  const renderRow3 = () => {
    if (numberOfCards > 8) return renderRow(numberOfCards - 8, 3);
  };
  return (
    <div>
      <div className="one-player-cards" >
        {/* <div style={{ height: Math.floor(numberOfCards / 4) * 28 }}> */}
        {renderRow1()}
        {renderRow2()}
        {renderRow3()}
      </div>
    </div>
  );
};
