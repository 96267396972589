import { useContext, useState } from "react";
import { GameContext } from "../../Context/GameContext";

import { useNavigate } from "react-router-dom";
import { Page } from "../../Components/Page/Page";

import Button from '@mui/material/Button';
import './StartGamePage.css';
import {Textinput, TitleHeader} from './../../Components';

const StartGamePage = () => {
  const navigate = useNavigate();

  const { initGame, setIsGameCreater, joinGame } = useContext(GameContext);

  const [joinGameId, setJoinGameId] = useState("");

  const startNewGame = () => {
    setIsGameCreater(true);
    initGame(joinGameId);
  };

  const joinGameHandler = () => {
    joinGame(joinGameId);
    setIsGameCreater(false);
  };

  
  return (
    <Page>
      <div>
        <TitleHeader/>
        <div className="textfieldContainer">
          <Textinput id="gameid_input" placeholder="Spiel Name..." variant="outlined"  onChange={(e) => setJoinGameId(e)} />
        </div>
        <div  className="buttonContainer">
          <Button className="button" onClick={joinGameHandler} variant="contained">Spiel beitreten</Button>
          <Button className="button" onClick={startNewGame} variant="contained">Neues Spiel</Button>
        </div>
      </div>
    </Page>
  );
};

export default StartGamePage;
