import { useContext } from 'react'

import './DarkModeToggle.css'
import { GameContext } from '../../Context/GameContext'


export const DarkModeToggle = () => {

    const { changeMode } = useContext(GameContext);


    return (
        <div class="darkmode-toggle-container">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" onClick={changeMode}>
                <circle cx="12" cy="12" r="5" />
                <path d="M12 2v2M12 18v2M5.6 5.6l1.4 1.4M17.99 17.99l1.42 1.42M5.6 17.99l1.4-1.4M17.99 5.6l1.42 1.42M2 12h2M18 12h2M5.6 12l1.4-.01M17.99 12l1.42-.01M12 5.6l-.01 1.4M12 17.99l-.01 1.42" stroke-width="2" />
            </svg>
        </div>
    )
}