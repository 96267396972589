import PropTypes from 'prop-types';

import './WaitingUsers.css';


export const WaitingUsers = ({ count }) => {
    const getUserIcons = () => {
        const res = [];
        for (let i = 0; i < count; i++) {
            res.push(
                <WaitingUser />
            );
        }
        return res;
    }
    return (
        <div id="waitingUsersContainer">
            {getUserIcons()}
        </div>
    )
}


const WaitingUser = () => {

    return (
        <div class="waitingUser">
            <svg width="100%" height="100%" viewBox="0 0 512 512" id="_x30_1" version="1.1">
                {/* <svg fill="#000000"  id="_x30_1" version="1.1"> */}
                <g>
                    <ellipse cx="256" cy="130" rx="110" ry="130" />
                    <path d="M36,478.191C36,390.825,134.497,320,256,320s220,70.825,220,158.191v0C476,496.863,460.863,512,442.192,512H69.808   C51.137,512,36,496.863,36,478.191L36,478.191z" />
                </g>
            </svg>
        </div>
    )
}


WaitingUsers.spropTypes = {
    count: PropTypes.number
};