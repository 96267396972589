import { useContext } from "react";
import { GameContext } from "../../Context/GameContext";
import Modal from "@mui/material/Modal";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { DarkModeToggle } from "../DarkModeToggle/DarkModeToggle";

import "./PageStyles.css";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "80vw",
  bgcolor: 'var(--main)',
  color: 'var(--text)',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export const Page = (props) => {
  const { showModal, modalText } = useContext(GameContext);

  return (
    <div id="page" style={{ height: "100vh", overflowY: "auto" }}>
      <div style={{ display: 'flex', justifyContent: 'end', padding: '3%' }}>
        <DarkModeToggle />
      </div>
      {props.children}
      <Modal open={showModal} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={style}>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {modalText}
          </Typography>
        </Box>
      </Modal>
    </div>
  );
};
