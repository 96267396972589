import { createBrowserRouter, RouterProvider } from "react-router-dom";

import GamingPage from "../Pages/GamePage/GamePage";
import WaitForPlayerPage from "../Pages/WaitPage/WaitForPlayerPage";
import StartGamePage from "../Pages/StartGamePage/StartGamePage";

const router = createBrowserRouter([
  {
    path: "/",
    element: <StartGamePage />,
  },
  {
    path: "/wait",
    element: <WaitForPlayerPage />,
  },
  {
    path: "/play",
    element: <GamingPage />,
  },
]);

export default router;
