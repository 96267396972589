
import "./Textinput.css";

export const Textinput = ({ onChange, placeholder }) => {
    const onChangeHandler = (ev) => {
        onChange(ev.target.value);
    }
    return (<input class="custom_input" onChange={onChangeHandler} placeholder={placeholder}/>)
}

Textinput.defaultProps = {
    placeholder: "",
    onchange: () => {}
};