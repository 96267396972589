

// export const API_URL = "http://mind-api.toex-it.de";
// export const API_URL = "http://www.toex-it.de:5000/api";
// export const API_URL = "http://localhost:5000";

export const SOCKET_PATH =  window.location.hostname.includes('toex-it.de') ? '/api/socket.io' : ''
export const SOCKET_HOST =  window.location.hostname.includes('toex-it.de') ? '' : 'http://localhost:80/'

// export const API_URL = "http://192.168.2.127:5000";
// const url = "http://localhost:5000/";