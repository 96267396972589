import {useEffect, useState} from 'react';

const DARKMODE_CLASS_NAME = 'dark-mode'

export const useDarkMode = () => {

    const [isDark, setcurrMode] = useState();

    useEffect(() => {
        let initialModeString = localStorage.getItem("DarkMode");
        let initialMode;
        if (initialModeString == null){
            initialMode = true;
        }
        else {
            initialMode = true ? initialModeString == 'true' : false;
        }
        if (initialMode){
            document.body.classList.add(DARKMODE_CLASS_NAME)
        }
    }, [])

    const changeMode = () => {
        const newMode = !isDark
        if (newMode){
            document.body.classList.add(DARKMODE_CLASS_NAME)
        }
        else {
            document.body.classList.remove(DARKMODE_CLASS_NAME)
        }
        setcurrMode(newMode);
        localStorage.setItem("DarkMode", newMode);
    }

    
    return [isDark, changeMode]

}