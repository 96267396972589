import { useState, createContext, useRef } from "react";
import { useSocket, useDarkMode } from "../Hooks";


import { useNavigate } from "react-router-dom";
import { useHistory } from "react-router";

export const GameContext = createContext({});

export const GameContextProvider = ({ children }) => {
  const [cards, setCards] = useState([]);
  const [isGameCreater, _setIsGameCreater] = useState(sessionStorage.getItem("gameCreater") === "true");
  const [countOfPlayers, setCountOfPlayers] = useState(0);
  const [leben, setLeben] = useState(0);
  const [anzahlWurfsterne, setAnzahlWurfsterne] = useState(0);
  const [level, setLevel] = useState(0);
  const [modalText, setModalText] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [playersCards, setPlayersCards] = useState({});

  const [cardStack, setCardStack] = useState([]);
  const [wurfsternStack, setWurfsternStack] = useState([]);

  const [gameId, setGameId] = useState("");
  const [userId, setUserId] = useState("");

  const [isDark, changeMode] = useDarkMode();

  const modalMessageQueue = [];
  let showModal_ = false;

  const navigate = useNavigate();

  const onLevelStarted = () => {
    const url = window.location.href;
    const splitted_url = url.split("/");
    if (splitted_url[splitted_url.length - 1] == "wait") {
      navigate("/play");
    }
  };

  const removeTopCard = () => {
    setCards((cards) => cards.slice(1, cards.length));
  };
  const onGameEnded = () => {
    sessionStorage.removeItem('gameId');
    sessionStorage.removeItem('userId');
    sessionStorage.removeItem('gameCreater');
    navigate("/");
  };

  const onGameJoined = (newGameId, userId) => {
    setUserId(userId);
    sessionStorage.setItem('gameId', newGameId);
    sessionStorage.setItem('userId', userId);
    navigate("/wait");
  };


  const showModalHandler = (text) => {
    modalMessageQueue.push(text);
    if (!showModal_) workModalMessageQueue();
  };

  const workModalMessageQueue = () => {

    console.log(modalMessageQueue);
    if (modalMessageQueue.length == 0) {
      setShowModal(false);
      setModalText("");
      showModal_ = false;
    } else {
      const message = modalMessageQueue.shift();
      setModalText(message);
      setShowModal(true);
      showModal_ = true;
      setTimeout(() => {
        workModalMessageQueue();
      }, 2000);
    }
  };

  const setUserAndGameId = ({ userId, gameId }) => {
    sessionStorage.setItem("UserId", userId);
    sessionStorage.setItem("GameId", gameId);
  }

  const setIsGameCreater = (value) => {
    _setIsGameCreater(value);
    sessionStorage.setItem("gameCreater", value);
  }



  const {
    doReconnectToGame,
    socketConnection,
    initGame,
    joinGame,
    startGame,
    playCard: playCardSocket,
    endGame: endGameSocket,
    doWurfstern: doWurfsternSocket,
  } = useSocket(
    {
      setCards,
      setGameId,
      setCountOfPlayers,
      onLevelStarted,
      removeTopCard,
      setLeben,
      onGameEnded,
      setLevel,
      onGameJoined,
      showModalHandler,
      setCardStack,
      setPlayersCards,
      setWurfsternStack,
      setAnzahlWurfsterne,
      setUserId,
      setUserAndGameId
    }
  );

  const startGameHandler = () => {
    startGame(gameId);
  };

  const playCard = (number) => {
    playCardSocket(gameId, number);
  };

  const endGame = () => {
    endGameSocket(gameId);
  };
  const doWurfstern = () => {
    doWurfsternSocket(gameId);
  };

  const reconnectToGameAsUser = (gameId, userId) => {
    doReconnectToGame(gameId, userId);
  }

  return (
    <GameContext.Provider
      value={{
        gameId,
        setGameId,
        cards,
        setCards,
        socketConnection,
        initGame,
        joinGame,
        startGame,
        playCard,
        isGameCreater,
        setIsGameCreater,
        countOfPlayers,
        startGameHandler,
        leben,
        level,
        modalText,
        setModalText,
        showModal,
        setShowModal,
        endGame,
        doWurfstern,
        cardStack,
        playersCards,
        wurfsternStack,
        anzahlWurfsterne,
        isDark,
        changeMode,
        userId,
        reconnectToGameAsUser
      }}
    >
      {children}
    </GameContext.Provider>
  );
};
