import { useContext, useEffect } from "react";
import { GameContext } from "../../Context/GameContext";
import { useNavigate } from "react-router-dom";
import { Card } from "../../Components/Card/Card";
import { Page } from "../../Components/Page/Page";
import Lives from "../../Components/Lives/Lives";
import Wurfsterne from "../../Components/Wurfsterne/Wurfsterne";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
// import { PlayersCards } from "../../Components/PlayersCards/PlayersCards";
import { PlayersCards, Loader } from '../../Components';


import "./GamePage.css";

const GamingPage = () => {
  const {
    cards,
    playCard,
    gameId,
    leben,
    level,
    isGameCreater,
    endGame,
    doWurfstern,
    cardStack,
    playersCards,
    wurfsternStack,
    anzahlWurfsterne,
    reconnectToGameAsUser
  } = useContext(GameContext);

  const navigate = useNavigate();

  const renderCards = () => {
    return (
      <div id="mycards_cardContainer">
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 8, sm: 8, md: 12 }}
        >
          {cards.map((card) => (
            <Grid item xs={2} sm={2} md={2} key={card}>
              <Card onClick={playCard} number={card} />
            </Grid>
          ))}
        </Grid>
      </div>
    );
  };

  const renderCardStack = () => {
    return cardStack.map((number, index) => (
      <div style={{ position: "absolute", zIndex: index, left: index * 25 }}>
        <Card number={number} small={false} />
      </div>
    ));
  };

  const renderWurfsternStack = () => {
    return wurfsternStack.map((number, index) => (
      <div style={{ position: "absolute", zIndex: index, left: index * 17 }}>
        <Card number={number} small={true} />
      </div>
    ));
  };
  useEffect(() => {
    if (gameId == "") {
      const userIdSessionStorage = sessionStorage.getItem("UserId")
      const gameIdSessionStorage = sessionStorage.getItem("GameId")
      if (userIdSessionStorage == null || gameIdSessionStorage == null) {
        navigate("/");
      } else {
        reconnectToGameAsUser(gameIdSessionStorage, userIdSessionStorage);
      }
    }
  }, []);

  useEffect(() => {
    const container = document.getElementById('cardStackContainer');
    if (container){
      var maxScrollLeft = container.scrollWidth - container.clientWidth;
      container.scrollLeft = maxScrollLeft;
    }
  }, [cards])

  const endGameHandler = () => {
    const end = window.confirm("Spiel für alle Spieler beenden?");
    if (end) {
      endGame();
    }
  };

  const wurfSternHandler = () => {
    const confirmation = window.confirm("Wurfstern spielen?");
    if (confirmation) {
      doWurfstern();
    }
  };

  return (
    <Page>
      {
        !gameId && <Loader />
      }
      {
        gameId &&
        <>
          <div>
            <br />
            <div>
              <p>Level: {level}</p>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Lives count={leben} />
              <Wurfsterne count={anzahlWurfsterne} />
            </div>
            <div style={{ width: "100%" }}>
              <PlayersCards cards={playersCards} />
            </div>
            {wurfsternStack.length > 0 && (
              <div
                className="cardStackContainer small"
                style={{ marginBottom: "10px" }}
              >
                <div style={{ width: "100%", position: "relative" }}>
                  {renderWurfsternStack()}
                </div>
              </div>
            )}
            <div id="cardStackContainer" className="cardStackContainer">
              <div style={{ position: "relative" }}>{renderCardStack()}</div>
            </div>
            <br />
            <p>Meine Karten:</p>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "96vw",
              }}
            >
              {renderCards()}
            </div>
            {(isGameCreater) && (
              <div className="buttonContainer small">
                {anzahlWurfsterne > 0 && (
                  <Button
                    style={{ marginTop: "5vh" }}
                    className="button"
                    onClick={wurfSternHandler}
                    variant="contained"
                  >
                    Wurfstern
                  </Button>
                )}
                <Button
                  className="button"
                  onClick={endGameHandler}
                  variant="contained"
                >
                  Spiel beenden
                </Button>
              </div>
            )}
          </div>
        </>
      }
    </Page>
  );
};

export default GamingPage;
