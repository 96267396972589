import { createBrowserRouter, RouterProvider } from "react-router-dom";

import logo from "./logo.svg";
import "./App.css";
import { useCallback, useState, useContext } from "react";
import useSocket from "./Hooks/useSocket";

import router from "./Routes/gameRoutes";
import { GameContext, GameContextProvider } from "./Context/GameContext";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import GamingPage from "./Pages/GamePage/GamePage";
import WaitForPlayerPage from "./Pages/WaitPage/WaitForPlayerPage";
import StartGamePage from "./Pages/StartGamePage/StartGamePage";
import JoinGamePage from "./Pages/JoinGamePage/JoinGamePage";

function App() {
  const { gameId, setGameid } = useContext(GameContext);

  return (
    // <Router>

    <GameContextProvider>
      <div className="App">
        {/* <div style={{ height: "200px", backgroundColor: "blue" }}>The mind </div> */}

        <Routes>
          <Route path="/play" element={<GamingPage />} />
          <Route path="/wait" element={<WaitForPlayerPage />} />
          <Route path="/join/:gameId" element={<JoinGamePage />} />
          <Route path="/" element={<StartGamePage />} />
        </Routes>
      </div>
    </GameContextProvider>
    // </Router>
  );
}

export default App;
