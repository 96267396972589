import { useEffect, useState } from "react";
import { SOCKET_HOST, SOCKET_PATH } from "../Config";
import { io } from "socket.io-client";
import { useNavigate } from "react-router-dom";

export var socket = null;

let socketConnection;

const useSocket = (
  { setCards,
    setGameId,
    setCountOfPlayers,
    setLeben,
    onGameEnded,
    setLevel,
    onGameJoined,
    showModalHandler,
    setCardStack,
    setPlayersCards,
    setWurfsternStack,
    setAnzahlWurfsterne,
    setUserAndGameId,
    onLevelStarted }
) => {

  
  const navigate = useNavigate();
  useEffect(() => {

    socket = io(SOCKET_HOST, { path: SOCKET_PATH });

    socket.on('reconnect', () => alert("RECONNECTED"));

    socket.on("GameJoined", (message) => {
      console.log(message);
      setGameId(message.gameId);
      setLeben(message.leben);
      onGameJoined(message.gameId, message.userId);
    });


    socket.on("UserAndGameId", (message) => {
      setUserAndGameId(message);
      onGameJoined(message.gameId, message.userId);
    });

    socket.on("LevelStarted", () => {
      onLevelStarted();
    });
    socket.on("ErrorResponse", (message) => {
      showModalHandler(message.error);
    });

    socket.on("UpdateClient", (message) => {
      if (message) {
        console.log(message);
        console.log(typeof (message));
        setCards(message.playerCards);
        setGameId(message.gameId);
        setPlayersCards(message.oponentCards);
        setCardStack(message.cardStack);
        setLevel(message.level);
        if (message.messages != undefined && message.messages.length > 0) showModalHandler(message.messages.join(" "));
        setWurfsternStack(message.wurfsternCards);
        setLeben(message.anzahlLeben);
        setAnzahlWurfsterne(message.anzahlWurfstene);
        setCountOfPlayers(message.anzahlSpieler);

        const url = window.location.href;
        const splitted_url = url.split("/");

        if (message.isEnded) {
          onGameEnded();
        }
        
      }
    });
  }, []);

  const initGame = (gameIdGiven) => {
    if (socketIsConnected()) {
      socket.emit("InitGame", gameIdGiven);
    } else alertReloadPage();
  };

  const joinGame = (gameId, waitUntilConnected = false) => {
    if (socketIsConnected()) {
      console.log("INVOKE STARTGAME");
      socket.emit("JoinGame", gameId);
    }
    else if (waitUntilConnected) {
      setTimeout(() => {
        joinGame(gameId, waitUntilConnected)
      }, 1000)
    }
    else alertReloadPage();
  };

  const startGame = (gameId) => {
    if (socketIsConnected()) {
      socket.emit("StartGame", gameId);
    }
    else alertReloadPage();
  };
  const endGame = (gameId) => {
    if (socketIsConnected()) {
      socket.emit("EndGame", gameId);
    }
    else alertReloadPage();
  };

  const playCard = (gameId, number) => {
    if (socketIsConnected()) {
      socket.emit("PlayCard", gameId, number);
    }
    else alertReloadPage();
  };

  const doWurfstern = (gameId) => {
    if (socketIsConnected()) {
      socket.emit("DoWurfStern", gameId);
    }
    else alertReloadPage();
  };

  const doReconnectToGame = (gameId, userId) => {
    if (socketIsConnected()){
      socket.emit("ReconnectToGame", gameId, userId);
    }
    else {
      setTimeout(() => {
        doReconnectToGame(gameId, userId)
      }, 1000)
    }

  }

  const socketIsConnected = () => socket != null && socket.connected;

  const alertReloadPage = () => showModalHandler("Keine Verbindung zum Server - bitte Seite neu laden");

  return {
    socketConnection,
    initGame,
    joinGame,
    startGame,
    playCard,
    endGame,
    doWurfstern,
    doReconnectToGame
  };
};

export { useSocket };
