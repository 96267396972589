import { GameContextProvider } from "./Context/GameContext";
import App from "./App";

import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";

function AppContainer() {

  return (
    <Router>

    
      <App />
    </Router>
    // </GameContextProvider>
  );
}

export default AppContainer;
