import { useContext, useEffect } from "react";
import { GameContext } from "../../Context/GameContext";

import { useParams } from "react-router-dom";
import { Page, Loader } from "../../Components";


const JoinGamePage = () => {
  let { gameId } = useParams();
  
  const { joinGame } = useContext(GameContext);

  useEffect(() => {
    if (gameId != "" && gameId != undefined)
      joinGame(gameId, true);
  }, [])

  return (
    <Page>
      <Loader />
    </Page>
  );
};

export default JoinGamePage;
