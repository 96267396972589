import { useContext, useEffect } from "react";
import { GameContext } from "../../Context/GameContext";
import { useNavigate } from "react-router-dom";
import { Page } from "../../Components/Page/Page";
import Button from "@mui/material/Button";
import { TitleHeader, WaitingUsers, Loader } from "../../Components";

import './WaitForPlayerPage.css';

const WaitForPlayerPage = () => {
  const { countOfPlayers, isGameCreater, gameId, startGameHandler, reconnectToGameAsUser } = useContext(GameContext);

  const navigate = useNavigate();

  const shareJoinLink = async () => {
    const location = window.location.host;
    const shareLink = `${window.location.protocol}//${location}/join/${gameId}`;

    const shareData = {
      title: "Spiel beitreten",
      url: shareLink,
      text: "Test"
    }

    navigator.share(shareData)
      .then(() => {
        console.log('Sharing was successful');
      })
      .catch((error) => {
        console.error('Sharing failed:', error);
      });
  }

  useEffect(() => {
    // Page was reloaded and no gameID is in Context => reconnect with stored User ID in session storage.
    // If no userID in session storage => redirect to home page
    if (gameId == "") {
      const userIdSessionStorage = sessionStorage.getItem("UserId")
      const gameIdSessionStorage = sessionStorage.getItem("GameId")
      if (userIdSessionStorage == null || gameIdSessionStorage == null) {
        navigate("/");
      } else {
        reconnectToGameAsUser(gameIdSessionStorage, userIdSessionStorage);
      }
    }
  }, [])

  return (
    <Page>
      {
        !gameId && <Loader />
      }
      {/* TODO: Schönes Conditional Rendering? */}
      {

        gameId && <>
          <TitleHeader />
          <h2>{gameId}</h2>
          <WaitingUsers count={countOfPlayers} />
          {(isGameCreater) && (
            <div className="buttonContainer" style={{ marginTop: "5vh", padding: "5" }}>
              <Button className="button" onClick={startGameHandler} variant="contained">
                Spiel starten
              </Button>
              <Button style={{ marginLeft: "1vw" }} className="button" onClick={shareJoinLink} variant="contained">
                Link teilen
              </Button>
            </div>
          )}
        </>
      }
    </Page>
  );
};

export default WaitForPlayerPage;
