import "./Wurfsterne.css";

const Wurfsterne = ({ count }) => {
  const getSVG = () => {
    return (
      <svg
        version="1.1"
        id="Capa_1"
        width="15px"
        height="15px"
        viewBox="0 0 30 30"
      >
        <g id="SVGRepo_bgCarrier" stroke-width="0" />
        <g
          id="SVGRepo_tracerCarrier"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <g id="SVGRepo_iconCarrier">
          {" "}
          <g>
            {" "}
            <path d="M11.001,30l2.707-16.334H5L11.458,0l9.25,0.123L16.667,8H25L11.001,30z" />{" "}
          </g>{" "}
        </g>
      </svg>
    );
  };

  const renderWurfsterne = () => {
    const components = [];
    for (let i = 0; i < count; i++) {
      components.push(getSVG());
    }
    return components;
  };

  return <div className="wurfsterne-container">{renderWurfsterne()}</div>;
};

export default Wurfsterne;
