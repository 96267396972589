import "./Lives.css";

const Lives = ({ count }) => {
  const getSVG = () => {
    return (
      <svg
        fill="#ff0000"
        width="15px"
        height="15px"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        stroke="#ff0000"
      >
        <g id="SVGRepo_bgCarrier" stroke-width="0" />

        <g
          id="SVGRepo_tracerCarrier"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <g id="SVGRepo_iconCarrier">
          <path d="M14 20.408c-.492.308-.903.546-1.192.709-.153.086-.308.17-.463.252h-.002a.75.75 0 01-.686 0 16.709 16.709 0 01-.465-.252 31.147 31.147 0 01-4.803-3.34C3.8 15.572 1 12.331 1 8.513 1 5.052 3.829 2.5 6.736 2.5 9.03 2.5 10.881 3.726 12 5.605 13.12 3.726 14.97 2.5 17.264 2.5 20.17 2.5 23 5.052 23 8.514c0 3.818-2.801 7.06-5.389 9.262A31.146 31.146 0 0114 20.408z" />
        </g>
      </svg>
    );
  };

  const renderHearts = () => {
    const components = [];
    for (let i = 0; i < count; i++) {
      components.push(getSVG());
    }
    return components;
  };

  return <div className="lives-container">{renderHearts()}</div>;
};

export default Lives;
